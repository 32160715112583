import { documentTypeConstants } from '../constants/documentTypeConstants';

export function documentTypeReducers(state = { documentTypes: [], responseStatus: false }, action) {
  switch (action.type) {
    case documentTypeConstants.DOCUMENT_TYPE_GET_REQUEST:
      return {
        ...state,
        responseStatus: true,
      };
    case documentTypeConstants.DOCUMENT_TYPE_GET_SUCCESS:
      let documentTypes= action.documentTypes.DocumentTypes
      return {
        ...state,
        documentTypes: documentTypes,
        responseStatus: false
      };
    case documentTypeConstants.DOCUMENT_TYPE_GET_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    default:
      return state
  }
}

  