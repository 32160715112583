import { depotOrderConstants } from '../constants/depotOrderConstants';

export function depotOrderReducers(state = { depotOrders: [], responseStatus: false }, action) {
  switch (action.type) {
    case depotOrderConstants.DEPOT_ORDER_GET_BY_REF_DOC_NO_CRITERIA_REQUEST:
      return {
        ...state,
        responseStatus: true,
      };
    case depotOrderConstants.DEPOT_ORDER_GET_BY_REF_DOC_NO_CRITERIA_SUCCESS:
      return {
        ...state,
        depotOrders: action.depotOrders,
        responseStatus: false
      };
    case depotOrderConstants.DEPOT_ORDER_GET_BY_REF_DOC_NO_CRITERIA_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    case depotOrderConstants.CLEAN_DEPOT_ORDERS:
      return {
        ...state,
        depotOrders: [],
      };
    default:
      return state
  }
}

  