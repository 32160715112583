import appointmentUpdateConstants from '../constants/appointmentUpdateConstants';

const appointmentUpdateStore = {
  editAppointment: false,
  ErrorValidation: false,
  Message: null,
  Result: 0
}

const appointmentUpdateReducers = (state = appointmentUpdateStore, action) => {
  switch (action.type) {
    case appointmentUpdateConstants.UPDATE_APPOINTMENT_REQUEST:
      return {
        ...state
      }
    case appointmentUpdateConstants.UPDATE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        ...action.param,
        editAppointment: true
      }
    case appointmentUpdateConstants.UPDATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        ...action.param,
        editAppointment: true
      }
    case appointmentUpdateConstants.UPDATE_APPOINTMENT_RESET:
      return {
        ...state,
        editAppointment: false,
        Result: 0,
        Message: null,
        ErrorValidation: false
      }
    default:
      return state;
  }
}

export default appointmentUpdateReducers;