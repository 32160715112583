import { equipmentConstants } from '../constants/equipmentConstants';

export function equipmentReducers(state = { equipments: [], responseStatus: false }, action) {
  switch (action.type) {
    case equipmentConstants.EQUIPMENT_GET_REQUEST:
      return {
        ...state,
        responseStatus: true,
      };
    case equipmentConstants.EQUIPMENT_GET_SUCCESS:
      let equipments = action.equipments.Equipments;
      return {
        equipments: equipments,
        responseStatus: false
      };
    case equipmentConstants.EQUIPMENT_GET_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    default:
      return state
  }
}

  