import { combineReducers } from 'redux';
import { authentication } from './authReducers';
import { alert } from './alertReducers';
import { userReducers } from './userReducers';
import { appointmentReducers, appointmentCreateReducers } from './appointmentReducers';
import appointmentUpdateReducers from './appointmentUpdateReducers';
import appointmentViewReducers from './appointmentViewReducers';
import appointmentCancelReducers from './appointmentCancelReducers';
import { driverReducers } from './driverReducers';
import { documentTypeReducers } from './documentTypeReducers';
import { applicationSettingReducers } from './applicationSettingReducers';
import { depotOrderReducers } from './depotOrderReducers';
import { equipmentReducers } from './equipmentReducers';

const rootReducer = combineReducers({
  authentication,
  applicationSettingReducers,
  alert,
  userReducers,
  appointmentReducers,
  appointmentCreateReducers,
  appointmentUpdateReducers,
  appointmentViewReducers,
  appointmentCancelReducers,
  documentTypeReducers,
  depotOrderReducers,
  driverReducers,
  equipmentReducers
});

export default rootReducer;
