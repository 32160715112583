const appointmentConstants = {
    APPOINTMENT_GET_BY_USER_REQUEST: 'APPOINTMENT_GET_BY_USER_REQUEST',
    APPOINTMENT_GET_BY_USER_SUCCESS: 'APPOINTMENT_GET_BY_USER_SUCCESS',
    APPOINTMENT_GET_BY_USER_FAILURE: 'APPOINTMENT_GET_BY_USER_FAILURE',
    CLEAN_APPOINTMENTS: 'CLEAN_APPOINTMENTS',

    APPOINTMENT_GET_BY_SCHEDULE_REQUEST: 'APPOINTMENT_GET_BY_SCHEDULE_REQUEST',
    APPOINTMENT_GET_BY_SCHEDULE_SUCCESS: 'APPOINTMENT_GET_BY_SCHEDULE_SUCCESS',
    APPOINTMENT_GET_BY_SCHEDULE_FAILURE: 'APPOINTMENT_GET_BY_SCHEDULE_FAILURE',
    CLEAN_APPOINTMENT_SCHEDULE:'CLEAN_APPOINTMENT_SCHEDULE',

    APPOINTMENT_GET_AUTOCOMPLETE_REQUEST: 'APPOINTMENT_GET_AUTOCOMPLETE_REQUEST',
    APPOINTMENT_GET_AUTOCOMPLETE_FAILURE: 'APPOINTMENT_GET_AUTOCOMPLETE_FAILURE',

    APPOINTMENT_GET_BY_ID_REQUEST: 'APPOINTMENT_GET_BY_ID_REQUEST',
    APPOINTMENT_GET_BY_ID_SUCCESS: 'APPOINTMENT_GET_BY_ID_SUCCESS',
    APPOINTMENT_GET_BY_ID_FAILURE: 'APPOINTMENT_GET_BY_ID_FAILURE',

    APPOINTMENT_CANCEL_BY_ID_REQUEST: 'APPOINTMENT_CANCEL_BY_ID_REQUEST',
    APPOINTMENT_CANCEL_BY_ID_SUCCESS: 'APPOINTMENT_CANCEL_BY_ID_SUCCESS',
    APPOINTMENT_CANCEL_BY_ID_FAILURE: 'APPOINTMENT_CANCEL_BY_ID_FAILURE',
    APPOINTMENT_CANCEL_RESET: 'APPOINTMENT_CANCEL_RESET',
    
    APPOINTMENT_CLEAN_FORM: 'APPOINTMENT_CLEAN_FORM',
    APPOINTMENT_CREATE: 'APPOINTMENT_CREATE',
    APPOINTMENT_CREATE_DEPOT: 'APPOINTMENT_CREATE_DEPOT',
    APPOINTMENT_CREATE_EQUIPMENT: 'APPOINTMENT_CREATE_EQUIPMENT',
    APPOINTMENT_CREATE_DRIVER: 'APPOINTMENT_CREATE_DRIVER',
    APPOINTMENT_CREATE_REMOVE_EQUIPMENT: 'APPOINTMENT_CREATE_REMOVE_EQUIPMENT',

    SAVE_APPOINTMENT_REQUEST: 'SAVE_APPOINTMENT_REQUEST',
    SAVE_APPOINTMENT_SUCCESS: 'SAVE_APPOINTMENT_SUCCESS',
    SAVE_APPOINTMENT_FAILURE: 'SAVE_APPOINTMENT_FAILURE',

    APPOINTMENT_RESET: 'APPOINTMENT_RESET',

    UPDATE_APPOINTMENT_REQUEST: 'UPDATE_APPOINTMENT_REQUEST',
    UPDATE_APPOINTMENT_SUCCESS: 'UPDATE_APPOINTMENT_SUCCESS',
    UPDATE_APPOINTMENT_FAILURE: 'UPDATE_APPOINTMENT_FAILURE',
    UPDATE_APPOINTMENT_RESET: 'UPDATE_APPOINTMENT_RESET',
};

export default appointmentConstants