import appointmentDetailConstants from '../constants/appointmentDetailConstants';

const appointmentCancelStore = {
  cancelAppointment: false,
  Status: false,
  Message: null,
  ErrorValidation: false,
};

const appointmentCancelReducers = (state = appointmentCancelStore, action) => {
  switch (action.type) {
    case appointmentDetailConstants.APPOINTMENT_CANCEL_BY_ID_REQUEST:
      return {
        ...state,
      };
    case appointmentDetailConstants.APPOINTMENT_CANCEL_BY_ID_SUCCESS:
      return {
        ...state,
        ...action.param,
        cancelAppointment: true
      };
    case appointmentDetailConstants.APPOINTMENT_CANCEL_BY_ID_FAILURE:
      return {
        ...state,
        ...action.param,
        cancelAppointment: true
      };
    case appointmentDetailConstants.APPOINTMENT_CANCEL_RESET:
      return {
        ...state,
        cancelAppointment: false,
        Status: false,
        Message: null,
        ErrorValidation: false
      }
    default:
      return state;
  }
}

export default appointmentCancelReducers