import logger from 'redux-logger'
import {createStore, applyMiddleware, compose} from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createBrowserHistory } from 'history';
import { initialState } from './constants';
import rootReducer from './reducers/index'

export const history = createBrowserHistory();

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)))
