import { userConstants } from '../constants/userConstants';

const userState = {
  responseStatus: false,
  changePasswordResponse: false,
  user: null
};


export function userReducers(state = userState, action) {
    switch (action.type) {
      case userConstants.USER_ACCOUNT_REQUEST:
        return {
          ...state,
          responseStatus: false
        };
      case userConstants.USER_ACCOUNT_SUCCESS:
        return {
          user: action.user,
          responseStatus: true
        };
      case userConstants.USER_ACCOUNT_FAILURE:
        return { 
          responseStatus: false
        };
      case userConstants.USER_ACCOUNT_REMOVE:
          return {
            responseStatus: false
           };
      case userConstants.USER_CHANGEPASSWORD_REQUEST:
          return {
              ...state,
              changePasswordResponse: false
            };
      case userConstants.USER_CHANGEPASSWORD_SUCCESS:
      return {
          ...state,
          changePasswordResponse: true
        };
      default:
        return state
    }
  }

  