import appointmentConstants from '../constants/appointmentConstants';
import { appointmentModel } from '../../models/AppointmentModel';

const appoitmentStore = {
  appointments: [],
  responseStatus: false,
  appointmentsSchedule: [],
  autoCompleteIsRequest: false,
  appointmentModel
}

export function appointmentReducers(state = appoitmentStore, action) {
  switch (action.type) {
    case appointmentConstants.APPOINTMENT_GET_BY_USER_REQUEST:
      return {
        ...state,
        responseStatus: true,
      };
    case appointmentConstants.APPOINTMENT_GET_BY_USER_SUCCESS:
      return {
        ...state,
        appointments: action.result.Appointments ?? [],
        query: action.query,
        responseStatus: false
      };
    case appointmentConstants.APPOINTMENT_GET_BY_USER_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    case appointmentConstants.CLEAN_APPOINTMENTS:
      return {
        ...state,
        appointments: [],
        query: null
      };

    case appointmentConstants.APPOINTMENT_GET_BY_SCHEDULE_REQUEST:
      return {
        ...state,
        appointmentsSchedule: [],
        responseStatus: true,
      };
    case appointmentConstants.APPOINTMENT_GET_BY_SCHEDULE_SUCCESS:
      let appointmentsSchedule = action.appointments.AppointmentsSchedule;
      return {
        ...state,
        appointmentsSchedule: appointmentsSchedule,
        responseStatus: false
      };
    case appointmentConstants.CLEAN_APPOINTMENT_SCHEDULE:
      return {
        ...state,
        appointmentsSchedule: []
      };
    case appointmentConstants.APPOINTMENT_GET_BY_SCHEDULE_FAILURE:
      return {
        ...state,
        appointmentsSchedule: [],
        responseStatus: false
      };
    case appointmentConstants.APPOINTMENT_GET_AUTOCOMPLETE_REQUEST:
        return {
           ...state,
            autoCompleteIsRequest: true
          };
    case appointmentConstants.APPOINTMENT_GET_AUTOCOMPLETE_FAILURE:
      return {
          ...state,
          autoCompleteResponse: [],
          autoCompleteIsRequest: false
        };
    default:
      return state
  }
}

export function appointmentCreateReducers(state = appointmentModel, action) {
  switch (action.type) {
    case appointmentConstants.APPOINTMENT_CLEAN_FORM:
      return {
        ...state,
        yardScheduleHourDetailPersonalizeIsAvailables: [],
        appointmentEquipments: [],
        documentTypeId: '',
        referenceDocumentNo: '',
        depotOrderId: '',
        depotOrderNo: '',
        truckerName: '',
        truckerDocumentNo: '',
        driverName: '',
        driverDocumentNo: '',
        licensePlateNo: '',
        keyLabel: '',
      };
    case appointmentConstants.APPOINTMENT_CREATE:
      return {
        ...state,
        yardId: action.form.yardId,
        appointmentDate: action.form.schedule.Start,
        localAppointmentDate: action.form.schedule.StartString,
        actionType: action.form.actionType,
        operationType: action.form.operationType,
        appointmentTemplateId: action.form.schedule.AppointmentTemplateId,
        yardScheduleHourId: action.form.schedule.YardScheduleHourId,
        appointmentStart: action.form.schedule.Start,
        appointmentEnd: action.form.schedule.End,
        timeZone: new Date().getTimezoneOffset(),
        timeLimitHours : action.form.schedule.TimeLimitHours,
        groupId: action.form.groupId,
        timeZone: action.form.timeZone,
        languageId: action.form.languageId,
        keyLabel: action.form.keyLabel
      };
    case appointmentConstants.APPOINTMENT_CREATE_DEPOT:
      return {
        ...state,
        yardName: action.form.yardName,
        documentTypeId: action.form.documentTypeId,
        referenceDocumentNo: action.form.referenceDocumentNo,
        depotOrderId: action.form.depotOrderId,
        depotOrderNo: action.form.depotOrderNo,
        customerId: action.form.customerId,
        customerName: action.form.customerName,
        carrierId: action.form.carrierId,
        carrierName: action.form.carrierName,
        vesselId: action.form.vesselId,
        voyageId: action.form.voyageId,
        yardScheduleHourDetailPersonalizeIsAvailables: action.form.yardScheduleHourDetailPersonalizeIsAvailables
      };
    case appointmentConstants.APPOINTMENT_CREATE_EQUIPMENT:
      return {
        ...state,
        yardScheduleHourDetailPersonalizeIsAvailables: action.form.yardScheduleHourDetailPersonalizeIsAvailables,
        appointmentEquipments: action.form.equipmentSelected
      };
    case appointmentConstants.APPOINTMENT_CREATE_REMOVE_EQUIPMENT:
      let newEquipments = state.appointmentEquipments.filter(e => e.index !== action.index)
      return {
        ...state,
        appointmentEquipments: newEquipments
      };
    case appointmentConstants.APPOINTMENT_CREATE_DRIVER:
      return {
        ...state,
        appointmentEquipments: action.form.appointmentEquipments,
        truckerId: action.form.truckerId,
        truckerName: action.form.truckerName,
        truckerDocumentNo: action.form.truckerDocumentNo,
        driverId: action.form.driverId,
        driverName: action.form.driverName,
        driverDocumentNo: action.form.driverDocumentNo,
        licensePlateNo: action.form.licensePlateNo,
        result: '',
        message: '',
      };
      
    case appointmentConstants.SAVE_APPOINTMENT_REQUEST:
      return {
        ...state,
        result: '',
        message: '',
        responseStatus: true,
      };
    case appointmentConstants.SAVE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        result: action.appointment.Result,
        message: action.appointment.Message,
        appointmentNo: action.appointment.AppointmentNo,
        responseStatus: false
      };
    case appointmentConstants.SAVE_APPOINTMENT_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    default:
      return state
  }
}

  


  