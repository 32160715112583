import { userConstants } from './../constants/userConstants';

let token = JSON.parse(sessionStorage.getItem(userConstants.USER_TOKEN));
const initialState = token ? { loggedIn: true, token, numberOfAttempts: 0 } : { loggedIn: false, numberOfAttempts:0};

export const authentication = (state = initialState, action) =>{
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        token: action.token,
        numberOfAttempts: state.numberOfAttempts
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        token: action.token,
        numberOfAttempts: 0
      };
    case userConstants.LOGIN_FAILURE:
      return {
        numberOfAttempts: (state.numberOfAttempts + 1)
      };
    case userConstants.LOGOUT:
      return { numberOfAttempts: 0};
    default:
      return state
  }
}