import { driverConstants } from '../constants/driverConstants';

export function driverReducers(state = { bussinesPartner: {}, responseStatus: false }, action) {
  switch (action.type) {
    case driverConstants.BUSINESS_PARTNER_REQUEST:
      return {
        ...state,
        responseStatus: true,
      };
    case driverConstants.BUSINESS_PARTNER_SUCCESS:
      return {
        bussinesPartner: action.businessPartner,
        responseStatus: false
      };
    case driverConstants.BUSINESS_PARTNER_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    default:
      return state
  }
}

  