
export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const initialState = {

    applicationSettingReducers: {

        // This is here for sessionStorage
        webSessionGuid: null,

        // This is here to correlate
        searchRequestId: null,

        //  from the server
        selectedEquipmentId: EMPTY_GUID,

        applicationSettings: []
    }
}
export const userRoles = {
    operator: "Operator",
    limitedCarrier: "LimitedCarrier"
}
export const PWA_GROUP_ID = 50
