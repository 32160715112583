import appointmentViewConstants from "../constants/appointmentViewConstants";

const appointmentViewStore = {
  Appointment: {},
  Message: '',
  Result: 0,
};

const appointmentViewReducers = (state = appointmentViewStore, action) => {
  switch (action.type) {
    case appointmentViewConstants.APPOINTMENT_GET_BY_ID_REQUEST:
      return {
        ...state,
      }
    case appointmentViewConstants.APPOINTMENT_GET_BY_ID_SUCCESS:
    return {
        ...state,
        ...action.param,
        Appointment: {
          ...action.param.Appointment,
          TruckerDocument: action.param.Appointment.TruckerRUTNo,
          DriverDocument: action.param.Appointment.RUTNo
        }
      }
    case appointmentViewConstants.APPOINTMENT_GET_BY_ID_FAILURE:
      return {
        ...state,
        ...action.param
      }
    case appointmentViewConstants.APPOINTMENT_RESET:
      return {
        ...state,
        Appointment: {},
        Message: '',
        Result: 0
      }
    default:
      return state;
  }
}

export default appointmentViewReducers;