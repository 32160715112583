import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { IntlProvider } from 'react-intl';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { store, history } from './redux/store';
import theme from './theme';
import GlobalStyles from './GlobalStyles';
import * as serviceWorker from './serviceWorker';
import Pace from './shared/components/Pace';
import Page404 from './shared/components/Page404';
import enMessages from './intl/en.json';
import esMessages from './intl/es.json';


const LoggedInComponent = lazy(() => import('./logged_in/components/Main'));

const LoggedOutComponent = lazy(() => import('./logged_out/components/Main'));


const language = (navigator.languages && navigator.languages[0]) ||
                  navigator.language ||
                  navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
const messages = languageWithoutRegionCode === 'en' ? enMessages : esMessages;

function App() {
  return (
    <Provider store={store} >
      <IntlProvider locale={languageWithoutRegionCode} defaultLocale='es' messages={messages}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>          
            <CssBaseline />
            <GlobalStyles />
            <Pace color={theme.palette.primary.light} />
            <Suspense fallback={<div />}>
              <Switch>
                <Route path='/secure'>
                  <LoggedInComponent />
                </Route>
                <Route>
                  <LoggedOutComponent history={history}/>
                </Route>
                <Route path='*' exact component={Page404} />
              </Switch>
            </Suspense>
        </MuiThemeProvider>
      </BrowserRouter>
      </IntlProvider>
    </Provider>
    
  );
}

serviceWorker.register();

export default App;
