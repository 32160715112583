export const appointmentModel  = {
    yardId: '',
    yardName: '',
    appointmentDate: '',
    actionType: '',
    operationType: '',
    appointmentTemplateId: '',
    localAppointmentDate: '',
    yardScheduleHourId: 0,
    AppointmentId: '-1',
    AppointmentType: 0,
    appointmentStart: '',
    appointmentEnd: '',
    timeLimitHours: 0,
    timeZone: null,
    realAppointmentTimeStart: '',
    realAppointmentTimeEnd : '',
    documentTypeId: '',
    referenceDocumentNo: '',
    depotOrderId: '',
    depotOrderNo: '',
    customerId: '',
    customerName: '',
    carrierId: '',
    carrierName: '',
    vesselId: '',
    voyageId: '',
    yardScheduleHourDetailPersonalizeIsAvailables: [],
    appointmentEquipments: [],
    truckerId: '',
    truckerName: '',
    truckerDocumentNo: '',
    driverId: '',
    driverName: '',
    driverDocumentNo: '',
    licensePlateNo: '',
    languageId: '',
    groupId: '',
    timeZone: '',

    keyLabel: 'appointment',
    result: '',
    message: ''
}
