export const userConstants = {
    USER_TOKEN: 'USER_TOKEN_PWA',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',    
    LOGOUT: 'USERS_LOGOUT',

    YARDS: 'YARDS',

    USER_ACCOUNT: 'USER_ACCOUNT',
    USER_ACCOUNT_REQUEST: 'USER_ACCOUNT_REQUEST',
    USER_ACCOUNT_SUCCESS: 'USER_ACCOUNT_SUCCESS',
    USER_ACCOUNT_FAILURE: 'USER_ACCOUNT_FAILURE',
    USER_ACCOUNT_REMOVE: 'USER_ACCOUNT_REMOVE',

    USER_CHANGEPASSWORD_REQUEST: 'USER_CHANGEPASSWORD_REQUEST',
    USER_CHANGEPASSWORD_SUCCESS: 'USER_CHANGEPASSWORD_SUCCESS',
    USER_CHANGEPASSWORD_FAILURE: 'USER_CHANGEPASSWORD_FAILURE',

    USER_PREFERENCE_YARD: 'USER_PREFERENCE_YARD'

};