import React, { Fragment } from 'react';
import { Typography, Box } from '@material-ui/core';

const Page404 = ()=> {
    
    return (
      <Fragment>
        <Box mt={4}>
          <Typography variant='subtitle1' gutterBottom>
            Page not found 404
          </Typography>
        </Box>
      </Fragment>
    );

}

export default Page404;
