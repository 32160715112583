import { applicationSettingConstants } from '../constants/applicationSettingConstants';

export function applicationSettingReducers(state = { applicationSettings: [], responseStatus: false }, action) {
  switch (action.type) {
    case applicationSettingConstants.APPLICATION_SETTING_GET_BY_CREATION_CATEGORY_REQUEST:
      return {
        ...state,
        responseStatus: true,
      };
    case applicationSettingConstants.APPLICATION_SETTING_GET_BY_CREATION_CATEGORY_SUCCESS:
      return {
        ...state,
        applicationSettings: action.applicationSettings,
        responseStatus: false
      };
    case applicationSettingConstants.APPLICATION_SETTING_GET_BY_CREATION_CATEGORY_FAILURE:
      return {
        ...state,
        responseStatus: false
      };
    default:
      return state
  }
}

  